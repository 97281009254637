.who{
  margin-top: 60px;
}

.who__art{
  @media (max-width: 959px) {
    margin-bottom: 30px;
  }
}

.who__text{
  @media (min-width: 960px) {
    padding-right: 6%;
    width: 40%;
  }
}

.who__animated-1{
  animation: 3s infinite linear who1;
  transform: translate(255px, 54px);
}

.who__animated-2{
  animation: 3s infinite linear who2;
  transform: translate(107px, 4.996919px);
}
