.how-much{
  margin-top: 200px;
  position: relative;
}

.roadmap{
  padding-bottom: 45px;
  padding-top: 20px;
}

.milestone{
  background-color: var(--color-white);
  box-shadow: 0 0 15px var(--shadow);
  box-sizing: border-box;
  height: auto;
  margin-top: 80px;
  padding: 18px;
  width: 33%;
}

.milestone__title{
  color: var(--color-blue-dark);
  font-size: 2.25rem;
}

.milestone__date{
  color: var(--color-grey);
  font-size: 1.3125rem;
}

.milestones__pagination{
  bottom: 0;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  z-index: 101;
}

.milestones__bullet{
  background-color: var(--color-grey);
  border-radius: 50%;
  cursor: pointer;
  display: inline-block;
  height: 21px;
  margin-left: 6px;
  margin-right: 6px;
  transition: background-color .3s;
  width: 21px;
}

.milestones__bullet--active{
  background-color: var(--color-blue-dark);
}

.targets{
  margin-top: 15px;
}

.targets__title{
  align-items: center;
  display: flex;
  font-size: 1.125rem;
  margin-bottom: 6px;

  &:before{
    border: 1px solid var(--color-black);
    border-radius: 50%;
    content: '';
    display: inline-block;
    margin-right: 9px;
    height: 20px;
    width: 20px;
  }
}

.targets__title--completed{
  color: var(--color-grey);
  text-decoration: line-through;

  &:before{
    background: url("data:image/svg+xml,%3Csvg width='9' height='7' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.878 6.26a.912.912 0 0 1-.618-.227L1.114 3.95a.57.57 0 0 1 0-.813.57.57 0 0 1 .813 0l1.951 1.919 4.163-4.033a.57.57 0 0 1 .813 0 .57.57 0 0 1 0 .813L4.528 6.065c-.227.13-.455.195-.65.195z' fill='%23639E1C' fill-rule='nonzero'/%3E%3C/svg%3E") center no-repeat;
    border-color: var(--color-green);
  }
}

.targets__description{
  font-size: 0.9375rem;
  line-height: 1.2;
  padding-left: 30px;

  &:not(:last-of-type) {
    margin-bottom: 15px;
  }
}
