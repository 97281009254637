body {
  color: var(--color-black);
  font-family: var(--font-primary);
  font-size: 100%;
  -webkit-text-size-adjust: none;
}

h1, h2, h3, h4 {
  font-family: var(--font-secondary);
}

p{
  line-height: 1.3;

  &:not(:last-of-type) {
    margin-bottom: 21px;
  }
}

.is-blue-text {
  color: var(--color-blue);
}

.is-dark-blue-text {
  color: var(--color-blue-dark);
}

.is-nav-link{
  color: inherit;
  font-weight: 700;
  text-decoration: none;
  transition: color .3s;

  &:hover,
  &:focus{
    color: var(--color-blue);
  }

  @media (min-width: 768px) {
    font-size: 1.125rem;
  }
}

.is-nav-link--accent{
  text-transform: uppercase;
}

.is-flex{
  @media (min-width: 960px) {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
}

.is-flex--inverted{
  flex-direction: row-reverse;
}

.box-content {
  box-sizing: border-box;
  padding-left: 3%;
  padding-right: 3%;

  @media (min-width: 768px) {
    padding-left: 2%;
    padding-right: 2%;
  }
}

/*.main-content {
  padding-top: 120px;
}*/

.main-title {
  font-size: 2.375rem;

  @media (min-width: 768px) {
    font-size: 3.75rem;
  }
}

.main-subtitle {
  font-size: 1.125rem;

  @media (min-width: 768px) {
    font-size: 1.5rem;
  }
}

.section-title{
  font-size: 2rem;
  margin-bottom: 15px;

  @media (min-width: 768px) {
    font-size: 3rem;
  }
}

.section-header{
  @media (min-width: 960px) {
    padding-left: 15%;
    padding-right: 15%;
    text-align: center;
  }
}

.section-description{
  font-size: 1.125rem;

  @media (min-width: 960px) {
    font-size: 1.3125rem;
  }
}

.section-description--short{
  @media (min-width: 960px) {
    padding-left: 15%;
    padding-right: 15%;
  }
}

.section-cta{
  margin-top: 82px;
}

.section-cta--solo{
  text-align: center;
}

.section-art{
  height: auto;
  width: 100%;

  @media (min-width: 768px) {
    width: 45vw;
  }
}
