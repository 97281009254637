.how{
  margin-top: 160px;
  position: relative;
}

.contexts{
  margin-bottom: 66px;
  margin-top: 52px;

  @media (min-width: 960px) {
    align-items: center;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 2%;
  }
}

.context{
  background-color: var(--color-white);
  box-shadow: 0 0 15px var(--shadow);
  padding: 18px 3%;
  text-align: center;
  transition: box-shadow .3s;

  &:hover{
    box-shadow: 8px 8px 15px var(--shadow);
  }

  @media (max-width: 959px) {
    margin-bottom: 24px;
  }
}

.context__icon{
  align-items: center;
  background-color: var(--color-light-blue);
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  height: 50px;
  margin-bottom: 15px;
  width: 50px;
}

.context__title{
  color: var(--color-blue-dark);
  font-size: 1.5rem;
  margin-bottom: 21px;
}

.splits{
  display: flex;
  flex-direction: row;
  height: 590px;
}

.split{
  /*background-attachment: fixed;*/
  background-position: center center;
  background-repeat: no-repeat;
  height: 590px;
}

.split--1{
  background-image: url("../images/split/01.jpg");
}

.split--2{
  background-image: url("../images/split/02.jpg");
}

.gutter {
  position: relative;
}

.gutter-horizontal {
  cursor: col-resize;

  &:before{
    background-image: url("data:image/svg+xml,%3Csvg width='60' height='60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Ccircle fill='%233661EA' cx='30' cy='30' r='30'/%3E%3Cpath d='M47.303 39.979c-.325 0-.65-.081-.894-.325-.568-.487-.65-1.381-.162-2.031l5.199-6.174H8.554l5.199 6.174c.487.569.406 1.462-.162 2.03-.57.488-1.463.407-2.031-.162l-5.524-6.58a4.42 4.42 0 0 1 0-5.686l5.524-6.58c.487-.569 1.38-.65 2.03-.162.57.487.65 1.38.163 2.03l-5.199 6.174h42.892l-5.199-6.336c-.487-.569-.406-1.462.162-2.031.57-.487 1.463-.406 2.031.163l5.524 6.58a4.42 4.42 0 0 1 0 5.686l-5.524 6.58c-.325.406-.73.65-1.137.65z' fill='%23FFF' fill-rule='nonzero'/%3E%3C/g%3E%3C/svg%3E");
    content: '';
    display: block;
    height: 60px;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    width: 60px;
  }
}
