.what{
  @media (min-width: 960px) {
    min-height: 100vh;
  }
}

.what__art{
  @media (max-width: 959px) {
    margin-bottom: 45px;
  }
}

.what__text{
  @media (min-width: 959px) {
    width: 48%;
  }
}

.what__animated{
  animation: 6s infinite linear what;
  transform: translate(233px, 22px);
}
