@use "~swiper/swiper";

.celebrities{
  margin-top: 200px;
  position: relative;
}

.celebrities__navigation{
  cursor: pointer;
  fill: var(--color-blue-dark);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: fill .3s;
  z-index: 101;

  &:hover{
    fill: var(--color-blue);
  }
}

.celebrities__navigation--prev{
  left: 2%;
}

.celebrities__navigation--next{
  right: 2%;
}

.celebrity{
  box-sizing: border-box;
  padding-left: 20%;
  padding-right: 20%;
}

.celebrity__quote{
  font-size: 1.125rem;
  font-weight: 700;
  text-align: center;
}

.celebrity__person{
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 18px;
}

.celebrity__info{
  margin-left: 12px;
}

.celebrity__data{
  display: block;
  font-size: 0.875rem;
}

.celebrity__data--name{
  margin-bottom: 3px;
}

.celebrity__data--work{
  color: var(--color-blue-dark);
}
