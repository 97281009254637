.claim{
  @media (max-width: 959px) {
    margin-bottom: 100px;
  }

  @media (min-width: 960px) {
    min-height: 100vh;
  }
}

.claim__art{
  @media (max-width: 959px) {
    margin-bottom: 12px;
    padding-top: 3%;
  }

  @media (min-width: 960px) {
    padding-right: 6%;
  }
}

.claim__animated {
  animation: 6s infinite linear claim;
  transform: translate(133.663px, 66.77px);
}

.claim__text{
  @media (min-width: 960px) {
    width: 45%;
  }
}

.claim__actions{
  margin-top: 45px;
}
