.early{
  margin-top: 120px;
  position: relative;
}

.early__art{
  @media (max-width: 960px) {
    margin-bottom: 30px;
  }
}

.early__text{
  @media (min-width: 959px) {
    width: 45%;
  }
}

.early__animated-1{
  animation: 6s infinite linear early1;
  transform: translate(283.783948px, 14px);
}

.early__animated-2{
  animation: 6s infinite linear early2;
}
