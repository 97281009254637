.plus{
  margin-top: 160px;
}

.plus__text{
  margin-bottom: 30px;
}

.features{
  @media (min-width: 768px) {
    display: grid;
    grid-column-gap: 5%;
  }

  @media (min-width: 768px) and (max-width: 959px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (min-width: 960px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

.feature{
  margin-bottom: 24px;
  position: relative;
}

.feature__title{
  font-size: 1.5rem;
  margin-bottom: 24px;
  margin-top: 12px;
}

.feature__soon{
  background: linear-gradient(90deg, #EBBA36 0%, #FDD054 100%);
  border-radius: 2px;
  display: inline-block;
  font-size: 0.625rem;
  font-weight: 700;
  padding: 3px 6px;
  position: absolute;
  right: 0;
  text-transform: uppercase;
  top: 3px;
}
