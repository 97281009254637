.blob-container{
  overflow-x: hidden;
  position: absolute;
  width: 100%;
  z-index: -1;
}

.blob-container__1{
  padding-bottom: ((100% * 300/276)*2);
  top: -5%;
  right: 0;
}

.blob-container__2{
  left: 0;
  padding-bottom: ((100% * 300/374)*4);
  top: -18%;
}

.blob-container__3{
  left: 0;
  padding-bottom: ((100% * 300/464)*2);
  top: -5%;
}

.blob-container__4{
  padding-bottom: ((100% * 300/427)*2);
  right: 0;
  top: 50%;

  @media (min-width: 768px) {
    top: -10%;
  }
}

.blob{
  position: absolute;
  z-index: -1;
}

.blob__1{
  right: 10%;
  top: 0;
  transform: scale(2.5);
  transform-origin: top left;

  @media (min-width: 768px) {
    right: -5%;
    transform: scale(4);
    transform-origin: top right;
  }
}

.blob__2{
  left: -10%;
  top: 0;
  transform: scale(4);
  transform-origin: top left;
}

.blob__3{
  left: -20%;
  top: 0;
  transform: scale(2);
  transform-origin: top right;

  @media (min-width: 768px) {
    transform: scale(3);
    transform-origin: top left;
  }
}

.blob__4{
  right: -50%;
  top: 0;
  transform: scale(2);
  transform-origin: left top;

  @media (min-width: 768px) {
    right: -10%;
    transform: scale(2.8);
    transform-origin: right top;
  }
}
