@keyframes claim {
  0% {
    transform: translate(133.663px, 66.77px);
  }
  50% {
    transform: translate(133.663px, 60.77px);
  }
  100% {
    transform: translate(133.663px, 66.77px);
  }
}

@keyframes what {
  0% {
    transform: rotate(0deg) translate(233px, 22px);
  }
  25% {
    transform: rotate(1deg) translate(233px, 22px);
  }
  75% {
    transform: rotate(-1deg) translate(233px, 22px);
  }
}

@keyframes early1 {
  0% {
    transform: translate(283.783948px, 14px);
  }
  50% {
    transform: translate(283.783948px, 4px);
  }
  100% {
    transform: translate(283.783948px, 14px);
  }
}

@keyframes early2 {
  0% {
    transform: translateY(-10px);
  }
  50% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(-10px);
  }
}

@keyframes who1 {
  0% {
    transform: translate(255px, 54px);
  }
  50% {
    transform: translate(261px, 54px);
  }
  100% {
    transform: translate(255px, 54px);
  }
}

@keyframes who2 {
  0% {
    transform: translate(107px, 4.996919px);
  }
  50% {
    transform: translate(101px, 4.996919px);
  }
  100% {
    transform: translate(107px, 4.996919px);
  }
}
